<template>
  <div>
    <div v-if="!isLoading">
      {{ forms }}
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { GET_FORM_DATA } from "@/graphql/queries/forms";
import { fixButtonText } from "@/a11y";

// import NProgress from "nprogress";
const moment = require("moment");
const tz = require("moment-timezone");
import { EventBus } from "@/event-bus";
export default {
  watch: {
    isLoading(newValue, oldValue) {
      console.log(newValue, oldValue);
      // if (!newValue) {
      //   this.a11yfixes();
      // }
    },
  },
  data: () => ({
    forms: null,
    error: "",
    upcomingOnly: true,
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedID: null,
    selectedOpen: false,
    filteredEvents: [],
    display: "calendar",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    eventKicker: "",
    isLoading: true,
  }),
  created() {
    window.NProgress.start();
  },
  async mounted() {
    if (this.$refs.calendar) {
      this.$refs.calendar.checkChange();
    }
  },
  updated() {
    //console.log("updated");
    if (this.$refs.calendar) {
      this.$refs.calendar.checkChange();
    }
  },
  apollo: {
    forms: {
      query: GET_FORM_DATA,
      variables() {},
      error(error) {
        this.error = JSON.stringify(error.message);
      },
      result(ApolloQueryResult) {
        console.log(ApolloQueryResult);
        this.forms = this.forms.map((form) => {
          // event.start = moment(event.start)
          //   .tz(this.$myApp.config.timezone)
          //   .toDate();
          // event.end = moment(event.end)
          //   .tz(this.$myApp.config.timezone)
          //   .toDate();
          // //console.log(this.$myApp.config.events[event.type]["color"]);
          // if (this.$myApp.config.events[event.type]) {
          //   event.color = this.$myApp.config.events[event.type]["color"];
          // } else {
          //   event.color = "grey darken-4";
          // }
          // //event.color = this.colors[this.rnd(0, this.colors.length - 1)];
          // event.show = false;
          return form;
        });
        this.filteredForms = this.forms;

        // this.filterUpcoming();
        this.isLoading = false;
        window.NProgress.done();
      },
    },
  },
  methods: {
    async change() {
      console.log("change here");
      await this.$nextTick();
      this.a11yfixes();
    },
    a11yfixes() {
      console.log("a11y fixes here");
      fixButtonText("mdi-chevron-right", "Click to move forward");
      fixButtonText("mdi-chevron-left", "Click to move previous");
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    toggleEventView(val) {
      this.display = val;
    },
    filterUpcoming() {
      let now = new Date();
      this.filteredEvents = this.events.filter((event) => {
        if (event.end >= now) {
          return event;
        }
      });
    },
    toggleUpcoming(val) {
      console.log("upcoming only: ", val);

      if (val) {
        this.filterUpcoming();
      } else {
        this.filteredEvents = this.events;
      }
      this.$refs.calendar.checkChange();
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        // console.log(event.id);
        // this.$vuetify.goTo(`#page-top`);
        this.selectedEvent = event;
        this.selectedID = event.id;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
        //this.$vuetify.goTo(`#event-title-${this.selectedID}`);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
        console.log("event-id: ", this.selectedID);
      }

      nativeEvent.stopPropagation();
    },
    getRange(start, end, timed) {
      let range;
      let localStart = moment(start).tz(this.$myApp.config.timezone);
      let localEnd = moment(end).tz(this.$myApp.config.timezone);
      let daysBetween = moment(localEnd).diff(moment(localStart), "days");

      if (daysBetween === 0 && timed) {
        range = ` | ${localStart.format("h:mm a")} to ${localEnd.format(
          "h:mm a"
        )} | <span style='font-weight: 400'>${localStart.format(
          "MMMM DD, YYYY"
        )}</span>`;
      } else if (daysBetween === 0 && !timed) {
        range = ` | All Day  | <span style='font-weight: 400'>${localStart.format(
          "MMMM DD, YYYY"
        )}</span>`;
      } else if (daysBetween > 0) {
        range = ` | <span style='font-weight: 400'>${localStart.format(
          "MMMM D"
        )}</span> <span style='font-weight: 400'>through</span> <span style='font-weight: 400'>${localEnd.format(
          "MMMM D, YYYY"
        )}</span>`;
      }
      return range;
    },

    isItMultiday(start, end, timed) {
      let range;
      let localStart = moment(start).tz(this.$myApp.config.timezone);
      let localEnd = moment(end).tz(this.$myApp.config.timezone);
      let daysBetween = moment(localEnd).diff(moment(localStart), "days");
      let isItMultiday;

      if (daysBetween > 0) {
        isItMultiday = true;
      } else {
        isItMultiday = false;
      }

      return isItMultiday;
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    closeEvent() {
      console.log("click", this.selectedOpen);
    },
  },
};
</script>
