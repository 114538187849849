import gql from "graphql-tag";

const GET_FORM_DATA = gql`
  query Forms {
    forms(where: { type: "Conference Room Request" }) {
      id
      created_at
      updated_at
      type
      email
      form
    }
  }
`;

const GET_SINGLE_FORM_DATA = gql`
  query Forms {
    forms {
      id
      created_at
      updated_at
      type
      email
      form
    }
  }
`;

export { GET_FORM_DATA, GET_SINGLE_FORM_DATA };
